import GLightbox from 'glightbox'

var lightbox = GLightbox();
var lightboxInlineIframe = GLightbox({
  selector: '.videoModal',
  touchNavigation: false,
  slideEffect: 'none',
  draggable: false,
  skin: 'modal giftmodalwrapper videomodalwrapper',
});

function callModal() {
  lightboxInlineIframe.open();
}
