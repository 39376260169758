/**
 * Ajax Forms
 * ======================================
 * - global recaptcha js object required
 * - form validation handled via html5 required attribute
 * - handled with ajax/forms.php
 * - required [hidden] attribute css support
 * - recaptcha support https://developers.google.com/recaptcha/docs/v3
 */

const AJAX_URL = '/api/index.php';
const reCAPTCHA_site_key = "6Le--SUbAAAAAFbwYRj49pMtbv37FdmpnyljN6B7";
const reCAPTCHA_ENABLED = true;
const ELEMENTS = '.ajaxForm';
const SUCCESS_CLASS = 'is-send';
const HIDE_AFTER_SUBMIT = true;
const SPAM_PROTECTION = false;

class AjaxForm {
  constructor() {
    this.elements = document.querySelectorAll(ELEMENTS);
    this.ajaxUrl = AJAX_URL;

    this.elements.forEach(el => {
      el.addEventListener('submit', this.submitHandler);
    });
  }

  submitHandler = e => {
    const self = this;

    e.preventDefault();

    if (SPAM_PROTECTION && !this.spamProtection(e.target)) {
      location.reload();
      return false;
    }

    if (reCAPTCHA_ENABLED) {
      grecaptcha.ready(function () {
        grecaptcha.execute(reCAPTCHA_site_key, { action: 'submit' }).then(function (token) {
          self.ajaxHandler(e.target, token);
        });
      });
    } else {
      self.ajaxHandler(e.target, null);
    }
  };

  spamProtection = form => {
    const formData = new FormData(form);

    if (formData.getAll('website')[0].length) {
      return false;
    }

    return true;
  };

  ajaxHandler = (form, token) => {
    const formData = new FormData(form);

    if (reCAPTCHA_ENABLED) {
      formData.append('grecaptcha_token', token);
    }

    const parent = form.closest('.ajaxForm__parent');
    const body = parent.querySelector('.ajaxForm__body');
    const message = parent.querySelector('.ajaxForm__message');
    const message_success = parent.querySelector('.ajaxForm-success');
    const message_error = parent.querySelector('.ajaxForm-error');
    const submit = form.querySelector('button[type=submit]');

    submit.classList.add('is-loading');

    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.ajaxUrl, true);
    xhr.send(formData);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        try {
          const response = JSON.parse(xhr.responseText);

          message.removeAttribute('hidden');

          if (HIDE_AFTER_SUBMIT) {
            body.style.display = 'none';
          }

          if (xhr.status === 200 && response.status == 'success') {
            message_success.removeAttribute('hidden');
            document.getElementById('form1').scrollIntoView();
            submit.classList.remove('is-loading');
            parent.classList.add(SUCCESS_CLASS);

            var flatButton = document.getElementById("flatButton");
            if (flatButton) {
              gtag('event', 'conversion', {
                'send_to': 'AW-599199696/uXKGCOCdtfYCENCf3J0C'
              });
            }

            // Přidání do datové vrstvy
            const email = formData.get('email') || 'N/A';
            const phone = formData.get('phone') || 'N/A';
            const service = formData.get('service') || 'N/A';
            const formName = form.querySelector('input[name="ajaxForm"]').value || 'unknown';

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'formSubmission',
              formName: formName,
              formData: {
                email: email,
                phone: phone,
                service: service,
              },
            });

            console.log('Form submission logged to dataLayer:', {
              event: 'formSubmission',
              formName: formName,
              formData: {
                email: email,
                phone: phone,
                service: service,
              },
            });

          } else {
            message_error.removeAttribute('hidden');
            console.log('Server error or invalid response:', xhr, response.errors);
          }
        } catch (error) {
          message_error.removeAttribute('hidden');
          console.error('Invalid JSON response from server:', xhr.responseText);
        }

        submit.classList.remove('is-loading');
      }
    };
  };
}

new AjaxForm();
